import React, { useEffect } from "react";
import { ArrowLeft, Brain, Dot } from "lucide-react";
import { toast } from "sonner";
import { Header } from "./ui/Header";
import { Separator } from "~/shadcn/components/ui/separator";
import { Button } from "~/shadcn/components/ui/button";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MOCK_SEARCH_RESULTS } from "~/providers/MockSearchResults";

export const PersonDetailView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate(-1);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const person = useLocation().state.person;
  const primaryLabel = person.name ? person.name : person.email;
  const secondaryLabel = person.name ? person.email : null;

  return (
    <div className="flex flex-col w-full overflow-y-auto">
      <Header>
        <div className="flex items-center gap-2 text-sm w-full px-2">
          <Button variant="ghost" onClick={() => navigate(-1)}>
            <ArrowLeft className="w-4 h-4" />
          </Button>
          <span className="font-semibold">{primaryLabel}</span>
          {secondaryLabel && (
            <>
              <Dot className="w-4 h-4 text-gray-500" />
              <button
                className="text-gray-500"
                onClick={() => {
                  navigator.clipboard.writeText(person.email);
                  toast.success("Email copied to clipboard");
                }}
              >
                {secondaryLabel}
              </button>
            </>
          )}
        </div>
      </Header>

      <div className="flex flex-col gap-2 p-4 border rounded-lg m-4 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-pink-500/20">
        <div className="flex items-center gap-2 text-slate-500">
          <Brain className="w-4 h-4" />
          <div className="font-meidum text-sm">Connection Insight</div>
        </div>
        <div className="text-black">{person.explanation}</div>
      </div>

      <div className="text-black font-semibold px-6 pt-4">Relevant Emails</div>

      <div className="flex flex-col gap-4 p-4">
        {person.content?.map((email, idx) => (
          <div
            key={idx}
            className="flex flex-col items-start gap-4 text-sm border p-4 rounded-lg bg-white drop-shadow-sm"
          >
            <div className="flex flex-col gap-1">
              <div className="font-medium text-base">{email["subject"]}</div>
              <span className="text-gray-500">From: {email["from"]}</span>
              <span className="text-gray-500">To: {email["to"]}</span>
            </div>
            <Separator />
            <div dangerouslySetInnerHTML={{ __html: email["content"] }} />
          </div>
        ))}
      </div>
    </div>
  );
};
