import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { csrfToken } from "~/utils/helpers";

export default function useCreateLink() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await fetch("/api/links", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      });
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["links"] });
      toast.success("Link created");
    },
  });
}
