import { useMutation } from "@tanstack/react-query";
import { csrfToken } from "~/utils/helpers";

export default function useCreateSuggestion() {
  return useMutation({
    mutationFn: async ({ newFeedback, mood }: { newFeedback: string; mood: string }) => {
      const response = await fetch("/suggestions", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          content: newFeedback,
          mood,
        }),
      });

      return response.json();
    },
  });
}
