import React from "react";

import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "~/shadcn/components/ui/tooltip";

export const TooltipWrapper = ({
  text,
  side,
  children,
}: {
  text: string;
  side?: "top" | "right" | "bottom" | "left";
  children: React.ReactNode;
}) => {
  return (
    <TooltipProvider delayDuration={340}>
      <Tooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent side={side ?? "right"}>
          <p className="text-xs">{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
