import { useMutation, useQueryClient } from "@tanstack/react-query";
import { csrfToken } from "~/utils/helpers";

interface ComposeEmailParams {
  receiver_name: string;
  query: string;
  connector_name: string;
  template_name: string;
}

export default function useComposeEmail() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      receiver_name,
      query,
      connector_name,
      template_name,
    }: ComposeEmailParams) => {
      const response = await fetch("/compose_email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          receiver_name,
          query,
          connector_name,
          template_name,
        }),
      });
      return response.json();
    },
  });
}
