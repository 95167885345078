import { csrfToken } from "~/utils/helpers";

export const signOut = async () => {
    const response = await fetch("/users/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
    if (response.status !== 204) {
      throw new Error("Failed to log out");
    }
    return;
};