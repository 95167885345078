import React from "react";
import { Dot, ExternalLink, Link } from "lucide-react";
import { toast } from "sonner";
import { useApp } from "~/providers/AppProvider";
import { Button } from "~/shadcn/components/ui/button";

export const LinkRow = ({ link }: { link: any }) => {
  const { user } = useApp();

  return (
    <div className="flex flex-col gap-4 p-6 items-center justify-center w-full h-full">
      <div className="flex flex-col items-start rounded-xl shadow-lg p-8 w-80 gap-8 bg-white">
        <div className="bg-black w-14 h-14 rounded-full flex items-center justify-center text-white text-2xl font-bold">
          {user.name[0].toUpperCase()}
        </div>
        <div className="flex flex-col gap-2" items-start>
          <span className="font-semibold">{user.name}'s Network</span>
          <span className="bg-slate-200 text-slate-600 p-1 px-2 text-xs font-medium rounded-lg w-fit">
            All Connections
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 items-center w-80">
        <Button
          size="sm"
          onClick={() => {
            console.log(link);
            navigator.clipboard.writeText(link["url"]);
            toast.success("Copied link to clipboard");
          }}
          className="flex gap-2 items-center w-full"
        >
          <Link className="w-4 h-4" />
          Copy public page link
        </Button>
        <Button
          variant="ghost"
          size="sm"
          className="flex gap-2 items-center w-full"
          onClick={() => {
            window.open(link["url"], "_blank");
          }}
        >
          <ExternalLink className="w-4 h-4" />
          Open public page
        </Button>
      </div>
    </div>
  );
};
