import React from "react";
import { ArrowUp, Search } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { useSearch } from "~/providers/SearchProvider";
import { Network } from "~/models/Network";

export const SearchBar = ({
  placeholder,
  didSubmit,
  children,
}: {
  placeholder: string;
  didSubmit: (query: string) => void;
  children?: React.ReactNode;
}) => {
  const { query, setQuery } = useSearch();

  const handleSearch = (e: React.FormEvent) => {
    if (query.length === 0) return;
    e.preventDefault();
    didSubmit(query);
  };

  return (
    <form onSubmit={handleSearch} className="flex relative items-center w-full">
      <div className="flex flex-col gap-4 items-start bg-slate-100 rounded-lg m-4 w-full p-2">
        <div className="flex items-center w-full px-2">
          <Search className="w-4 h-4 text-slate-600" />
          <input
            className="w-full border-0 ring-0 focus:ring-0 focus:outline-none bg-transparent"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={placeholder}
          />
        </div>

        <div className="flex items-center w-full justify-between">
          {children ? children : <div className="w-full flex" />}

          <Button
            size="sm"
            variant="outline"
            type="submit"
            disabled={query.length === 0}
          >
            <ArrowUp strokeWidth={2.4} className={"w-4 h-4"} />
          </Button>
        </div>
      </div>
    </form>
  );
};
