import React from "react";
import { cn } from "~/utils/helpers";

export function FeatureRow({
  icon,
  title,
  description,
  color,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
}) {
  return (
    <div className="flex items-center gap-4 w-full text-sm">
      <div
        className={cn(
          "flex items-center justify-center w-8 h-8 p-2 rounded-lg text-white",
          color
        )}
      >
        {icon}
      </div>
      <div className="flex flex-col">
        <span className="font-medium">{title}</span>
        <span className="text-gray-500">{description}</span>
      </div>
    </div>
  );
}
