import React, { useState } from "react";
import { useApp } from "~/providers/AppProvider";

export const SearchEmptyState = () => {
  const [open, setOpen] = useState(false);
  const { user } = useApp();

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col items-center text-sm gap-2 max-w-md">
        <p className="text-slate-800">Search and find the right person 🍃</p>

        <div className="w-full h-[1px] bg-slate-200 my-6" />
        <span className="text-slate-500 font-medium">Example Queries</span>
        <span className="text-slate-400 font-mono text-xs text-center">
          "python engineer looking to join an early stage startup"
        </span>
        <span className="text-slate-400 font-mono text-xs text-center">
          "venture capitalist looking to invest in AI"
        </span>

        {/* <div className="flex flex-col items-start gap-2">
          <div className="rounded-lg shadow p-1 bg-gradient-to-b from-white to-slate-100 mb-4">
            <Logo className="w-12 h-12 rounded-lg shadow " />
          </div>
          <p className="text-xl font-bold">Join the global network</p>
          <p className="text-slate-700 mb-4">
            Expand your network and increase serendipity by joining the KOAH
            network
          </p>
          <Button variant="outline" onClick={() => setOpen(true)}>
            Let's go
          </Button>
        </div> */}
      </div>
    </div>
  );
};
