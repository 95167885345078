import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Search } from "./Search";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { AppProvider, useApp } from "../providers/AppProvider";
import { TabProvider, useTab } from "~/providers/TabContextProvider";
import { Separator } from "~/shadcn/components/ui/separator";
import { SideMenu } from "./SideMenu";
import { ShareView } from "./link/ShareView";
import { toast, Toaster } from "sonner";
import { ProfileView } from "./ProfileView";
import { ExternalSearch } from "./ExternalSearch";
import { PersonDetailView } from "./PersonDetailView";
import { SearchProvider } from "~/providers/SearchProvider";
import {
  CheckCircle,
  CirclePlus,
  Copy,
  Dot,
  ExternalLink,
  Plus,
} from "lucide-react";
import { useFetchAllLinks } from "~/queries/fetch-links";
import { Button } from "~/shadcn/components/ui/button";
import { JoinNetworkDialog } from "./search/JoinNetworkDialog";

export const queryClient = new QueryClient();

const SharingIsCaringDialog = () => {
  const navigate = useNavigate();
  const { setSelectedTab } = useTab();

  return (
    <div
      className="flex flex-col gap-2 p-4 border border-gray-200 rounded-lg w-full text-xs"
      onClick={() => {
        setSelectedTab("share");
        navigate("/share");
      }}
    >
      <span className="text-slate-500 font-medium">Sharing is caring</span>
      <span className="">
        Create your personal link and share your network in seconds
      </span>

      <button
        className={`flex justify-center items-center border rounded-lg bg-black hover:bg-black/80 px-[8px] py-[6px] mt-4 transition-colors`}
      >
        <Plus strokeWidth={2.4} className="w-4 h-4 mr-2 text-white" />
        <span className="text-sm text-white font-medium">Create link</span>
      </button>
    </div>
  );
};

const MyLinkShortcuts = () => {
  const { data: links } = useFetchAllLinks();

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg w-full text-xs items-start p-1">
      <div className="text-xs text-gray-500 font-medium px-2 py-2">My Link</div>
      <Button
        size="sm"
        variant="ghost"
        className="flex justify-start gap-2 w-full"
        onClick={() => {
          window.open(links[0]["url"], "_blank");
        }}
      >
        <ExternalLink className="w-4 h-4 text-gray-500 mr-1" />
        <span>View public page</span>
      </Button>
      <Button
        size="sm"
        variant="ghost"
        className="flex justify-start gap-2 w-full"
        onClick={() => {
          navigator.clipboard.writeText(links[0]["url"]);
          toast.success("Copied link to clipboard");
        }}
      >
        <Copy className="w-4 h-4 text-gray-500 mr-1" />
        <span>Copy public page link</span>
      </Button>
    </div>
  );
};
export const RootView = () => {
  const { data: links } = useFetchAllLinks();
  const [open, setOpen] = useState(false);
  const { user } = useApp();

  return (
    <div className="flex items-center h-[100vh] w-screen justify-center">
      <div className="flex max-w-3xl w-[760px] h-full relative">
        <SideMenu />
        <Separator className="h-full" orientation="vertical" />
        <Outlet />
        <Separator className="h-full" orientation="vertical" />

        <div className="items-start w-64 absolute right-[-260px] top-0 py-5 z-50 gap-2 hidden md:flex-col md:flex pl-4 h-full">
          <a
            href="https://koahlabs.com"
            target="_blank"
            className="text-xs text-black font-medium"
          >
            KOAH
          </a>

          {user.has_global_access && (
            <div className="text-xs text-gray-500 mb-2 flex items-center border rounded-full pr-3">
              <Dot className="h-6 w-6 text-green-500" />
              <span>Global Network Member</span>
            </div>
          )}

          <div className="flex gap-2 items-start w-fit">
            <a
              className="text-xs text-gray-500 hover:underline cursor-pointer"
              href="https://koahlabs.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              className="text-xs text-gray-500 hover:underline cursor-pointer"
              href="mailto:team@koahlabs.com"
              target="_blank"
            >
              Contact
            </a>
          </div>

          {!user.has_global_access && (
            <button
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-blue-600 rounded-xl mt-2 flex items-center border border-blue-800 hover:from-blue-700 hover:to-blue-700 transition-all hover:scale-105"
              onClick={() => setOpen(true)}
            >
              <CirclePlus
                strokeWidth={2.4}
                className="w-4 h-4 mr-2 text-white"
              />
              <span className="text-sm text-white font-medium">
                Join the KOAH network
              </span>
            </button>
          )}

          <div className="flex grow" />

          {links?.length === 0 ? (
            <SharingIsCaringDialog />
          ) : (
            <MyLinkShortcuts />
          )}
        </div>

        <JoinNetworkDialog open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootView />,
    children: [
      {
        path: "/",
        element: <Search />,
      },
      {
        path: "/result/:id",
        element: <PersonDetailView />,
      },
      {
        path: "/link/:key",
        element: <ExternalSearch />,
      },
      {
        path: "/share",
        element: <ShareView />,
      },
      {
        path: "/profile",
        element: <ProfileView />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <SearchProvider>
          <TabProvider>
            <RouterProvider router={router} />
            <Toaster
              position="bottom-left"
              icons={{
                success: (
                  <CheckCircle
                    strokeWidth={2.4}
                    className="w-4 h-4 text-green-500"
                  />
                ),
              }}
            />
          </TabProvider>
        </SearchProvider>
      </AppProvider>
    </QueryClientProvider>
  );
};
