import React, { useEffect, useState } from "react";
import {
  FlagIcon,
  FrownIcon,
  Loader2Icon,
  MehIcon,
  SmileIcon,
} from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/components/ui/dialog";
import { Textarea } from "~/shadcn/components/ui/textarea";
import { cn } from "~/utils/helpers";
import useCreateSuggestion from "~/queries/submit-suggestion";
import { toast } from "sonner";

export function FeedbackModalButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="cursor-pointer"
      >
        <FlagIcon className="w-5 h-5 text-[#A4A4A4]" />
      </div>

      <FeedbackModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export function FeedbackModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const [feedback, setFeedback] = useState("");
  const [selectedMood, setSelectedMood] = useState<"happy" | "neutral" | "sad">(
    null
  );
  const {
    mutate: createSuggestion,
    isPending,
    isSuccess,
    isError,
  } = useCreateSuggestion();

  const handleSubmit = () => {
    createSuggestion(
      {
        newFeedback: feedback,
        mood: selectedMood,
      },
      {
        onSuccess: () => {
          setFeedback("");
          setSelectedMood(null);
          setIsOpen(false);
          toast.success("Thanks for the feedback!");
        },
        onError: () => {
          toast.error("Error submitting feedback. Please try again later.");
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Feedback</DialogTitle>
          <DialogDescription>
            We'd love to hear what went well or how we can improve the product
            experience.
          </DialogDescription>
        </DialogHeader>
        <Textarea
          className="my-4"
          placeholder="Tell us more about your experience..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <DialogFooter className="flex items-center justify-center">
          <div className="flex justify-start w-full gap-2">
            {[
              { icon: SmileIcon, mood: "happy", color: "bg-green-300" },
              { icon: MehIcon, mood: "neutral", color: "bg-yellow-300" },
              { icon: FrownIcon, mood: "sad", color: "bg-red-300" },
            ].map(({ icon: Icon, mood, color }) => (
              <Button
                key={mood}
                variant="outline"
                size="icon"
                className={`w-8 h-8 ${
                  selectedMood === mood ? "border-2 border-primary" : ""
                }`}
                onClick={() => setSelectedMood(mood)}
              >
                <Icon
                  className={cn(
                    "h-5 w-5 rounded-full",
                    selectedMood === mood ? color : ""
                  )}
                />
                <span className="sr-only">{mood}</span>
              </Button>
            ))}
          </div>

          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button type="submit" size="sm" onClick={handleSubmit}>
            {isPending ? (
              <Loader2Icon className="w-4 h-4 animate-spin" />
            ) : (
              "Submit"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
