import React, { useState, useEffect } from "react";
import { Header } from "./ui/Header";
import { Separator } from "~/shadcn/components/ui/separator";
import { SearchBar } from "./search/SearchBar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { postExternalSearchQuery } from "~/queries/search-query";
import { useParams } from "react-router-dom";
import { SearchResultsTable } from "./search/SearchResultsTable";
import { useSearch } from "~/providers/SearchProvider";
import { TooltipWrapper } from "./ui/TooltipWrapper";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { SegmentedDialogContent } from "~/shadcn/components/ui/segmented-dialog";
import {
  Brain,
  Check,
  ChevronDown,
  Copy,
  Dot,
  Flame,
  Link2Icon,
  Loader2,
  Mail,
  Target,
  TriangleAlert,
  UsersRound,
} from "lucide-react";
import { ToolbarActionButton } from "./ui/ToolbarActionButton";
import { toast } from "sonner";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "~/shadcn/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/shadcn/components/ui/popover";
import { cn } from "~/utils/helpers";
import useComposeEmail from "~/queries/compose-email";
// @ts-ignore
import LinkedinIcon from "~/assets/images/linkedin.svg?react";
import { useApp } from "~/providers/AppProvider";

const ActionFooter = ({
  email,
  template,
}: {
  email: string;
  template: string;
}) => {
  return (
    <div className="flex gap-4 bg-white rounded-lg shadow-lg border h-fit p-4">
      <ToolbarActionButton
        listenKey="c"
        onClick={() => {
          navigator.clipboard.writeText(email);
          toast.success("Copied email to clipboard");
        }}
        icon={<Link2Icon className="w-4 h-4 text-blue-500" />}
        text="Copy email"
        shortcut="C"
      />
      <ToolbarActionButton
        listenKey="g"
        onClick={() => {
          window.open(
            `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=Introduction&body=${encodeURIComponent(
              template
            )}`,
            "_blank"
          );
        }}
        icon={<Mail className="w-4 h-4 text-red-500" />}
        text="Open Gmail"
        shortcut="G"
      />
      <div className="w-full" />
    </div>
  );
};

const templates = [
  {
    value: "warm intro",
    label: "Warm Intro",
    icon: <Flame className="w-4 h-4 text-red-400" />,
  },
  {
    value: "direct outreach",
    label: "Direct Outreach",
    icon: <Target className="w-4 h-4 text-purple-400" />,
  },
];

export function EmailTemplateDropdown({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button className="text-sm border rounded-full px-[8px] py-[6px] flex items-center hover:bg-slate-100 transition-colors">
          {templates.find((template) => template.value === value)?.icon}
          <span className="text-xs font-medium ml-2">
            {templates.find((template) => template.value === value)?.label}
          </span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-1">
        <Command>
          <CommandList>
            <CommandGroup>
              {templates.map((template) => (
                <CommandItem
                  key={template.value}
                  value={template.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === template.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {template.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export const ExternalSearchDetailView = ({
  connector_name,
  connector_email,
  person,
  open,
  setOpen,
}: {
  connector_name: string;
  connector_email: string;
  person: any;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { user } = useApp();
  const primaryLabel = person?.name ? `${person?.name}` : person?.email;
  const secondaryLabel = person?.name ? `${person?.email}` : null;

  const [template, setTemplate] = useState("warm intro");
  const [email, setEmail] = useState("");
  const toEmail = template == "warm intro" ? connector_email : person?.email;
  const toName = template == "warm intro" ? connector_name : person?.name;

  const { mutate, isPending } = useComposeEmail();
  const { query } = useSearch();

  const { data: linkedinProfile, isLoading } = useQuery({
    queryKey: ["linkedin_profile", person?.email],
    queryFn: () =>
      fetch(`/linkedin_profile?name=${person.name}&email=${person.email}`).then(
        (res) => res.json()
      ),
  });

  useEffect(() => {
    if (!person) {
      return;
    }

    if (template) {
      generateEmail(template);
    }
  }, [person, template]);

  function copyEmail(email: string) {
    navigator.clipboard.writeText(email);
    toast.success("Copied email to clipboard");
  }

  function generateEmail(template: string) {
    mutate(
      {
        receiver_name: primaryLabel,
        query: query,
        connector_name: connector_name,
        template_name: template,
      },
      {
        onSuccess: (data) => {
          setEmail(data.content);
        },
      }
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <SegmentedDialogContent className="bg-clear border-0 shadow-none w-[740px]">
        <div className="flex flex-col h-full w-full gap-4">
          <div className="flex flex-col gap-4 bg-white rounded-lg shadow-lg border w-full overflow-y-auto h-[600px]">
            <div className="flex flex-col items-start gap-1 px-4 pt-4">
              <div className="flex items-center gap-2">
                <div className="font-medium text-lg">{primaryLabel}</div>
                {linkedinProfile && (
                  <button
                    className="pr-4"
                    onClick={() => {
                      window.open(linkedinProfile["link"], "_blank");
                    }}
                  >
                    <LinkedinIcon className="w-5 h-5 text-blue-500" />
                  </button>
                )}
              </div>
              <button
                onClick={() => copyEmail(secondaryLabel)}
                className="focus:outline-none text-slate-500 hover:text-black transition-colors"
              >
                {secondaryLabel}
              </button>
            </div>

            <div className="flex flex-col gap-2 p-4 border border-purple-400/50 rounded-lg mx-4 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-pink-500/20">
              <div className="flex items-center gap-2 text-slate-500">
                <Brain className="w-4 h-4" />
                <div className="font-meidum text-sm">Connection Insight</div>
              </div>
              <div className="text-black">{person?.explanation}</div>
            </div>

            <Separator />

            <div className="px-4 flex items-center gap-2 justify-between w-full">
              <EmailTemplateDropdown value={template} setValue={setTemplate} />

              <button
                className="text-xs text-slate-500 flex items-center gap-2 hover:text-black transition-colors font-medium"
                onClick={() => {
                  navigator.clipboard.writeText(email);
                  toast.success("Copied email to clipboard");
                }}
              >
                Copy
                <Copy className="w-4 h-4" />
              </button>
            </div>

            <div className="text-sm px-4 h-full transition-all duration-100">
              <div className="bg-slate-100 rounded-lg p-4 whitespace-pre-wrap transition-all duration-100 min-h-[100px]">
                <div className="text-slate-500 text-xs font-medium mb-4">
                  SAMPLE EMAIL
                </div>

                <div className="text-slate-700 items-start flex flex-col text-xs font-medium mb-4 gap-1">
                  <button
                    className="hover:text-black transition-colors"
                    onClick={() => copyEmail(user?.email ?? "")}
                  >
                    From: {`${user?.name} <${user?.email}>`}
                  </button>
                  <button
                    className="hover:text-black transition-colors"
                    onClick={() => copyEmail(toEmail)}
                  >
                    To: {`${toName} <${toEmail}>`}
                  </button>
                </div>

                <div className="h-[1px] w-full bg-slate-300 mb-4" />
                {email && !isPending ? (
                  email
                ) : (
                  <div className="flex flex-col gap-2 animate-pulse">
                    <div className="h-4 bg-slate-200 rounded w-3/4"></div>
                    <div className="h-4 bg-slate-200 rounded w-1/2"></div>
                    <div className="h-4 bg-slate-200 rounded w-1/3"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ActionFooter email={person?.email} template={email} />
      </SegmentedDialogContent>
    </Dialog>
  );
};

const SearchErrorState = ({ error }: { error: Error }) => {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center p-4 gap-2">
      <TriangleAlert className="w-6 h-6 text-orange-500 mb-4" />
      <div className="text-xl font-medium">This page does not exist</div>
      <div className="text-gray-500">{error.message}</div>
    </div>
  );
};

export const ExternalSearch = () => {
  const { key } = useParams();
  const { setResults } = useSearch();
  const [openPreview, setOpenPreview] = useState(false);

  const mutation = useMutation({
    mutationFn: ({ query, link_key }: { query: string; link_key: string }) =>
      postExternalSearchQuery(query, link_key),
    onSuccess: (data) => {
      setResults(data);
    },
  });

  const { isLoading, error, data } = useQuery({
    queryKey: ["link", key],
    queryFn: async () => {
      const response = await fetch(`/api/links/${key}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch link");
      }
      const result = await response.json();
      return result;
    },
    retry: 1,
  });

  const [selectedPerson, setSelectedPerson] = useState<any>(null);

  return (
    <div className="flex flex-col w-full">
      <Header>
        <div className="flex gap-4 w-full h-full items-center px-5 justify-center bg-gradient-to-b from-blue-300/40 to-white">
          {error ? (
            <div className="w-full">
              <div className="text-black">Invalid link</div>
            </div>
          ) : isLoading ? (
            <div className="w-full">
              <Loader2 className="w-4 h-4 text-blue-600 animate-spin" />
            </div>
          ) : (
            <div className="flex h-full justify-start w-full gap-1 items-center">
              <div>{data?.name}'s Network</div>
              <Dot className="w-4 h-4" />
              <button
                className="hover:underline"
                onClick={() => {
                  navigator.clipboard.writeText(data?.email);
                  toast.success("Copied email to clipboard");
                }}
              >
                {data?.email}
              </button>
            </div>
          )}
        </div>
      </Header>

      {error ? (
        <SearchErrorState error={error} />
      ) : (
        <>
          <SearchBar
            placeholder={`Find people in ${data?.name}'s network...`}
            didSubmit={(query) => {
              mutation.mutate({ query: query, link_key: key });
            }}
          >
            <TooltipWrapper text="Search over this email">
              <div className="flex text-xs text-slate-700 px-[8px] py-[6px] border rounded-full bg-white">
                <UsersRound className="w-4 h-4 mr-2 text-slate-400" />
                {error ? "Error" : data?.name}
              </div>
            </TooltipWrapper>
          </SearchBar>

          <Separator />

          <SearchResultsTable
            mutation={mutation}
            setSelectedPerson={(person) => {
              setSelectedPerson(person);
              setOpenPreview(true);
            }}
          />

          <ExternalSearchDetailView
            connector_name={data?.name}
            connector_email={data?.email}
            person={selectedPerson}
            open={openPreview}
            setOpen={setOpenPreview}
          />
        </>
      )}
    </div>
  );
};
