import React, { useState } from "react";
import { Button } from "~/shadcn/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import {
  MoreHorizontal,
  ThumbsUp,
  ThumbsDown,
  MessageSquare,
} from "lucide-react";

export const ResultEllipsisButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFeedback = (type) => {
    console.log(`User marked the result as ${type}`);
    setIsOpen(false);
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          aria-label="More options"
          onClick={(e) => e.stopPropagation()}
        >
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleFeedback("good");
          }}
        >
          <ThumbsUp className="mr-2 h-4 w-4" />
          <span>This result was good</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleFeedback("bad");
          }}
        >
          <ThumbsDown className="mr-2 h-4 w-4" />
          <span>This result was bad</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleFeedback("mid");
          }}
        >
          <MessageSquare className="mr-2 h-4 w-4" />
          <span>Provide feedback</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
