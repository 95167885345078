import React from "react";
import { Separator } from "~/shadcn/components/ui/separator";

export const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col w-full min-h-[60px] sticky top-0 bg-white z-10">
      <div className="flex flex-col h-full w-full font-medium items-center justify-center text-sm">
        {children}
      </div>
      <Separator />
    </div>
  );
};
