import { useMutation } from "@tanstack/react-query";
import { queryClient } from "~/components/App";
import { csrfToken } from "~/utils/helpers";

export default function useJoinNetwork() {
  return useMutation({
    mutationFn: async (globalAccess: boolean) => {
      const response = await fetch("/join_network", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ global_access: globalAccess }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
}
