import React from "react";
import { Header } from "../ui/Header";
import { Dot, ExternalLink, Link, Loader2, Plus } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { toast } from "sonner";
import { useFetchAllLinks } from "~/queries/fetch-links";
import useCreateLink from "~/queries/create-link";
import { LinkRow } from "./LinkRow";

const ShareEmptyState = ({}: {}) => {
  const { mutate: createLink, isPending } = useCreateLink();

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col items-start text-sm gap-4 max-w-md">
        <div className="rounded-lg shadow p-2 border border-blue-500 bg-gradient-to-b from-blue-500/20 to-blue-500/10">
          <Link className="w-4 h-4 text-blue-900" />
        </div>
        <p className="text-xl font-semibold">Create your personal link</p>
        <p className="text-slate-700 mb-4">
          Let others search your network by sending them your personal link.
        </p>
        <Button onClick={() => createLink()} className="w-20 animate-in">
          {isPending ? (
            <Loader2 className="w-4 h-4 text-white animate-spin" />
          ) : (
            "Create"
          )}
        </Button>
      </div>
    </div>
  );
};

export const ShareView = () => {
  const { data: links } = useFetchAllLinks();
  const { mutate: createLink, isPending } = useCreateLink();

  function didClickCreate() {
    if (links.length >= 1) {
      toast.error("You can only have one link for now.");
    } else {
      createLink();
    }
  }

  return (
    <div className="flex flex-col w-full bg-slate-50/50">
      <Header>
        <div className="flex flex-row text-left items-center w-full px-6">
          <span className="w-full">My Links</span>

          <button
            className="flex flex-row items-center gap-2 bg-black rounded-lg p-2 w-20"
            onClick={didClickCreate}
          >
            {isPending ? (
              <Loader2 className="w-4 h-4 text-white" />
            ) : (
              <>
                <Plus className="w-4 h-4 text-white" strokeWidth={2.5} />
                <span className="text-white text-xs">New</span>
              </>
            )}
          </button>
        </div>
      </Header>

      {links?.length === 0 && <ShareEmptyState />}

      {links?.map((link) => (
        <LinkRow key={link.id} link={link} />
      ))}
    </div>
  );
};
