import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { SearchBar } from "./search/SearchBar";
import { Header } from "./ui/Header";
import { Separator } from "~/shadcn/components/ui/separator";
import { postGlobalSearchQuery, postSearchQuery } from "~/queries/search-query";
import {
  GlobalSearchResultsTable,
  SearchResultsTable,
} from "./search/SearchResultsTable";
import { useNavigate } from "react-router-dom";
import { Network } from "~/models/Network";
import { useSearch } from "~/providers/SearchProvider";
import { NetworkSelectorDropdown } from "./search/NetworkSelectorDropdown";
import { csrfToken } from "~/utils/helpers";
import { useApp } from "~/providers/AppProvider";
import { Button } from "~/shadcn/components/ui/button";
import { TriangleAlert } from "lucide-react";
import { JoinNetworkDialog } from "./search/JoinNetworkDialog";

export const Search = () => {
  const navigate = useNavigate();
  const { user } = useApp();
  const { results, setResults } = useSearch();
  const { query, setQuery, network, setNetwork } = useSearch();
  const [open, setOpen] = useState(false);
  const [initialNetworkSelectionRendered, setInitialNetworkSelectionRendered] =
    useState(false);

  const personalSearchMutation = useMutation({
    mutationFn: (query: string) => postSearchQuery(query),
    onSuccess: (data) => {
      setResults(data);
    },
  });

  const globalSearchMutation = useMutation({
    mutationFn: (query: string) => postGlobalSearchQuery(query),
    onSuccess: (data) => {
      setResults(data);
    },
  });

  useEffect(() => {
    if (initialNetworkSelectionRendered) {
      performSearch(query);
    } else {
      setInitialNetworkSelectionRendered(true);
    }
  }, [network]);

  function performSearch(query: string) {
    if (query === "") {
      return;
    }

    if (network === Network.koah) {
      globalSearchMutation.mutate(query);
    } else if (network === Network.personal) {
      personalSearchMutation.mutate(query);
    }
  }

  function changeNetwork(network: Network) {
    if (network === Network.koah && !user.has_global_access) {
      setOpen(true);
      return;
    }

    setNetwork(network);
  }

  return (
    <div className="flex flex-col w-full">
      <Header>Search People</Header>
      {user.has_email_scope ? (
        <>
          <div className="flex flex-col justify-between items-center w-full">
            <SearchBar
              placeholder={
                network === Network.koah
                  ? "Find people in the global network..."
                  : "Find people in your network..."
              }
              didSubmit={(query) => {
                setQuery(query);
                performSearch(query);
              }}
            >
              <NetworkSelectorDropdown
                value={network}
                setValue={changeNetwork}
              />
            </SearchBar>
          </div>
          <Separator />
          {network === Network.koah ? (
            <GlobalSearchResultsTable
              mutation={globalSearchMutation}
            />
          ) : (
            <SearchResultsTable
              mutation={personalSearchMutation}
              setSelectedPerson={(person) => {
                navigate(`/result/${person.email}`, { state: { person } });
              }}
            />
          )}
        </>
      ) : (
        <EmailPermissionsContent />
      )}

      <JoinNetworkDialog open={open} setOpen={setOpen} />
    </div>
  );
};

const EmailPermissionsContent = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col items-start gap-2 border border-yellow-400 bg-yellow-100 rounded-lg p-4 text-sm justify-center  max-w-md">
        <p className="text-yellow-700 font-semibold flex items-center gap-2">
          <TriangleAlert className="w-4 h-4 inline-block" /> Heads up
        </p>
        <p className="text-black">
          This feature requires Gmail read access. Please change your
          permissions and try again.
        </p>

        <form
          action={`${window.location.origin}/users/auth/google_oauth2?scope=gmail.readonly`}
          method="post"
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Button size="sm" type="submit" className="mt-6">
            Update Google Permissions
          </Button>
        </form>
      </div>
    </div>
  );
};
