import { csrfToken } from "~/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "~/components/App";

export const useFetchAllLinks = () => {
  return useQuery({
    queryKey: ["links"],
    queryFn: async () => {
      const response = await fetch(`/api/links`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch all links");
      }
      const data = await response.json();
      queryClient.setQueryData(["links"], data);
      return data;
    },
  });
};
