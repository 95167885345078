import { csrfToken } from "~/utils/helpers";

export const postSearchQuery = async (query) => {
  const response = await fetch(`/email_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ query: query }),
  });

  if (!response.ok) {
    throw new Error("Failed to search");
  }
  return await response.json();
};

export const postGlobalSearchQuery = async (query) => {
  const response = await fetch(`/global_email_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ query }),
  });

  if (!response.ok) {
    throw new Error("Failed to search");
  }
  return await response.json();
};


export const postExternalSearchQuery = async (query, link_key) => {
  const response = await fetch(`/external_email_search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    },
    body: JSON.stringify({ query, link_key }),
  });

  if (!response.ok) {
    throw new Error("Failed to search");
  }
  return await response.json();
};
