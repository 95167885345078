import React from "react";
import { Button } from "~/shadcn/components/ui/button";
import { Dialog, DialogContent } from "~/shadcn/components/ui/dialog";
import { Flower, Globe2, HandHeart, Loader2 } from "lucide-react";
// @ts-ignore
import Logo from "~/assets/images/logo.svg?react";
import { FeatureRow } from "../ui/FeatureRow";
import useJoinNetwork from "~/queries/join-network";
import { toast } from "sonner";

export const JoinNetworkDialog = ({ open, setOpen }) => {
  const { mutate, isPending, isSuccess, isError } = useJoinNetwork();
  const joinNetworkMutate = () => {
    mutate(true, {
      onSuccess: () => {
        setOpen(false);
        toast.success("Welcome to the KOAH network");
      },
      onError: () => {
        toast.error("Error joining. Please try again later.");
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <div className="flex flex-col gap-4 items-center">
          <div className="bg-slate-100 rounded-lg shadow p-1">
            <Logo className="w-10 h-10 rounded-lg shadow bg-gradient-to-b from-white to-slate-100" />
          </div>
          <div className="text-xl font-semibold">Join the global network</div>

          <div className="text-sm text-slate-600 text-center">
            Join the KOAH network and start searching through thousands of
            people that can help you achieve your goals.
          </div>

          <div className="flex flex-col gap-4 bg-slate-400/10 rounded-lg p-4 text-sm text-slate-700 w-full my-2">
            <FeatureRow
              icon={<Globe2 className="w-4 h-4" />}
              title="KOAH Network"
              description="Access a network of 10K+ people"
              color="bg-green-500"
            />
            <FeatureRow
              icon={<Flower className="w-4 h-4" />}
              title="Serendipitous connections"
              description="Find the person you would have never met otherwise"
              color="bg-pink-500"
            />
            <FeatureRow
              icon={<HandHeart className="w-4 h-4" />}
              title="Help others"
              description="Share your network with others in the network"
              color="bg-purple-500"
            />
          </div>

          <Button
            className="w-full text-white"
            onClick={() => joinNetworkMutate()}
            disabled={isPending}
          >
            {isPending ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              "Let's go"
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
