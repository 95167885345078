import React from "react";
import { useTab } from "~/providers/TabContextProvider";
// @ts-ignore
import UnselectedLinkIcon from "~/assets/images/link-unselected.svg?react";
// @ts-ignore
import SelectedLinkIcon from "~/assets/images/link-selected.svg?react";
// @ts-ignore
import UnselectedHomeIcon from "~/assets/images/home-unselected.svg?react";
// @ts-ignore
import SelectedHomeIcon from "~/assets/images/home-selected.svg?react";
import { useNavigate } from "react-router-dom";
import { useApp } from "~/providers/AppProvider";
import { FeedbackModalButton } from "./FeedbackModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { TooltipWrapper } from "./ui/TooltipWrapper";
import { Cog, LogOut } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { signOut } from "~/queries/sign-out";

export const SideMenu = () => {
  const { user } = useApp();
  const { selectedTab, setSelectedTab } = useTab();
  const navigate = useNavigate();

  const signOutMutation = useMutation({
    mutationFn: signOut,
    onSuccess: () => {
      window.location.reload();
    },
  });

  return (
    <div className="flex flex-col gap-8 p-4 items-center w-18 h-full z-20">
      <TooltipWrapper text="Home" side="right">
        <div
          onClick={() => {
            setSelectedTab("home");
            navigate("/");
          }}
          className="cursor-pointer"
        >
          {selectedTab === "home" ? (
            <SelectedHomeIcon />
          ) : (
            <UnselectedHomeIcon />
          )}
        </div>
      </TooltipWrapper>

      <TooltipWrapper text="My Links" side="right">
        <div
          onClick={() => {
            setSelectedTab("share");
            navigate("/share");
          }}
          className="cursor-pointer"
        >
          {selectedTab === "share" ? (
            <SelectedLinkIcon />
          ) : (
            <UnselectedLinkIcon />
          )}
        </div>
      </TooltipWrapper>

      <TooltipWrapper text="Feedback" side="right">
        <FeedbackModalButton />
      </TooltipWrapper>

      <DropdownMenu>
        <DropdownMenuTrigger>
          <div onClick={() => navigate("/profile")} className="cursor-pointer">
            <div className="flex w-8 h-8 bg-black items-center justify-center rounded-full">
              <span className="text-sm font-medium text-white">
                {user.email[0].toUpperCase()}
              </span>
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56">
          <DropdownMenuLabel>{user.email}</DropdownMenuLabel>

          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="flex gap-3"
            onClick={() => {
              navigate("/profile");
            }}
          >
            <Cog className="w-4 h-4 text-slate-400" />
            <span>Settings</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex gap-3"
            onClick={() => signOutMutation.mutate()}
          >
            <LogOut className="w-4 h-4 text-slate-400" />
            <span>Sign out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <div className="grow" />
    </div>
  );
};
